import request from './request'

const api = {
	// 获取系统配置
	getConfig: '/system/config/getConfig',
	// 设置系统配置
	setConfig: '/system/config/setConfig',
}

export {
	request,
	api
}
