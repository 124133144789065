<template>
	<div class="bg-Hight">
		<div class="content">
			<div class="title">
				基本参数
			</div>
			<ul class="list config">
				<li class="ftime">
					<div class="name">默认密码</div>
					<div class="input">
						<el-input clearable v-model="form.reset_password" placeholder="请输入默认密码"></el-input>
					</div>
				</li>
				<li @click="binType(4)">
					<div class="name">企业建会</div>
					<div class="input">
						<div class="imgList" v-if="form.background_building">
							<img class="dialog-img" :src="form.background_building" />
						</div>
						<el-upload accept=".png,.jpg" class="fupload" list-type="picture-card"
							:on-success="handleAvatarSuccess" :headers="Headers" :action="ActionUrl">
							<i class="icon iconfont icon-shangchuan"></i>
						</el-upload>
					</div>
				</li>
				<li @click="binType(5)">
					<div class="name">职工入会</div>
					<div class="input">
						<div class="imgList" v-if="form.background_employe">
							<img class="dialog-img" :src="form.background_employe" />
						</div>
						<el-upload accept=".png,.jpg" class="fupload" list-type="picture-card"
							:on-success="handleAvatarSuccess" :headers="Headers" :action="ActionUrl">
							<i class="icon iconfont icon-shangchuan"></i>
						</el-upload>
					</div>
				</li>
				<li @click="binType(1)">
					<div class="name">logo</div>
					<div class="input">
						<div class="imgList" v-if="form.logo">
							<img class="dialog-img" :src="form.logo" />
						</div>
						<el-upload accept=".png,.jpg" class="fupload" list-type="picture-card"
							:on-success="handleAvatarSuccess" :headers="Headers" :action="ActionUrl">
							<i class="icon iconfont icon-shangchuan"></i>
						</el-upload>
					</div>
				</li>
				<li @click="binType(2)">
					<div class="name">报告模板图片</div>
					<div class="input">
						<!-- 预览图 -->
						<div class="imgList" v-for="(item,index) in imgList" :key="index">
							<p @click="DelImg(index)">删除</p>
							<img class="dialog-img" :src="item" />
						</div>
						<el-upload accept=".png,.jpg" class="fupload" list-type="picture-card"
							:on-success="handleAvatarSuccess" :headers="Headers" :action="ActionUrl">
							<i class="icon iconfont icon-shangchuan"></i>
						</el-upload>
					</div>
				</li>
				<li @click="binType(3)">
					<div class="name">报告模板Word/PDF</div>
					<div class="input none-input">
						<el-upload multiple accept=".pdf,.doc,.docx,.xlm,.zip,.ppt,.xls,.xlsx,.rtf,.word"
							:headers="Headers" :action="ActionUrl" :on-success="handleAvatarSuccess">
							<div class="upload">
								<i class="icon iconfont icon-shangchuan"></i>
							</div>
						</el-upload>
						<div class="fileList" v-for="(item,index) in fileList" :key="index">
							<div>{{item.OriginalName}}<span @click="fileDel(index)">删除</span></div>
						</div>
					</div>
				</li>
			</ul>
			<ul class="list">
				<li>
					<div class="input">
						<el-button @click="submit" color="#488bff" class="el-white" style="color: white" size="small">
							保存修改</el-button>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/config";
	export default {
		data() {
			return {
				loading: false,
				Headers: {
					token: ''
				},
				ActionUrl: '',
				form: {
					logo: '',
					template: '',
					report_template: '',
					reset_password: '',
					background_employe:'',
					background_building:''
				},
				type: 1,
				imgList: [],
				fileList: []
			}
		},
		created() {
			// 上转图片url
			this.Headers.token = sessionStorage.getItem('token')
			this.Headers.appid = sessionStorage.getItem('appid')
			this.Headers.nonce = sessionStorage.getItem('nonce')
			this.Headers.timestamp = sessionStorage.getItem('timestamp')
			this.Headers.sign = sessionStorage.getItem('sign')


			this.ActionUrl = sessionStorage.getItem('baseURL') + '/system/Upload/FormUpload'
			this.getinfo()
		},
		methods: {
			// 删除图片
			DelImg(index) {
				this.imgList.splice(index, 1)
			},
			// 删除文件
			fileDel(index) {
				this.fileList.splice(index, 1)
			},
			// 提交
			submit() {
				this.form.template = this.imgList.length > 0 ? this.imgList.join(",") : ""
				this.form.report_template = this.fileList.length > 0 ? JSON.stringify(this.fileList) : ""

				this.$http.post(api.setConfig, this.form)
					.then((res) => {
						ElMessage({
							type: 'success',
							message: '更新成功',
						})
						this.getinfo()
					})
			},
			// 获取信息
			getinfo() {
				this.$http.post(api.getConfig)
					.then((res) => {
						this.form = res.data
						this.imgList = res.data.template ? res.data.template.split(",") : [],
							this.fileList = res.data.report_template ? JSON.parse(res.data.report_template) : []
					})

			},
			// 上传
			handleAvatarSuccess(res, file) {
				if (this.type == 1) {
					this.form.logo = res.data.url
				}
				if (this.type == 2) {
					this.imgList.push(res.data.url)
				}
				if (this.type == 3) {
					this.fileList.push(res.data)
				}
				if (this.type == 4) {
					this.form.background_building = res.data.url
				}
				if (this.type == 5) {
					this.form.background_employe = res.data.url
				}
			},
			// 类型
			binType(e) {
				this.type = e
			}
		}
	}
</script>

<style lang="scss" scoped>
	.fileList {
		margin-top: 10px;
		color: #666;
		font-size: 12px;

		span {
			margin-left: 10px;
			display: inline-block;
			cursor: pointer;
			color: #488bff;
		}
	}

	.imgList {
		p {
			padding: 3px 5px;
			position: absolute;
			right: 10px;
			top: 0;
			cursor: pointer;
			font-size: 12px;
			z-index: 1;
			color: #fff;
			background-color: rgba(0, 0, 0, 0.5);
		}
	}

	.list {
		padding-left: 60px;

		.appurl {
			padding-left: 150px;
			color: #666;
		}

		li {
			width: 100%;
			font-size: 14px;
			display: flex;
			margin-bottom: 18px;

			.name {
				padding: 0 12px 0 0;
				line-height: 32px;
				width: 150px;
			}

			.none-input {
				display: inline-block !important;
			}

			.input {
				display: flex;
				align-items: center;

				i {
					font-size: 45px;
				}

				.fupload {
					display: inline-block;
				}
			}
		}
	}

	.content {
		padding: 20px;

		.title {
			color: #000;
			margin: 30px 0;
			font-size: 15px;
			width: 100%;
			display: flex;
			border-bottom: 1px solid #f2f2f2;
			align-items: center;

			.current {
				color: #00ad79;
				border-bottom: 2px solid #00ad79;
			}

			p {
				height: 40px;
				line-height: 40px;
				margin-right: 30px;
				text-align: center;
				cursor: pointer;
			}
		}
	}
</style>